import * as React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql, Link } from 'gatsby';

import Layout from '../components/layout';

const SmallBoarding = ({ data }) => {
  var pageTitle = 'Small Animal Boarding';

  return (
    <Layout
      slug="small-boarding"
      title={pageTitle}
      description="Learn more about the variety of animals we can care for!"
    >
      <h1>{pageTitle}</h1>
      <div className="flex flex-wrap flex-col md:flex-row justify-between">
        <GatsbyImage
          image={getImage(data.image1)}
          alt="Laura goat"
          className="order-1 w-full md:w-2/7 mb-4 rounded-xl"
          style={{ maxWidth: 800 }}
        />
        <GatsbyImage
          image={getImage(data.image2)}
          alt="guinea pigs"
          className="order-3 w-full md:w-2/7 mb-4 rounded-xl"
          style={{ maxWidth: 800 }}
        />
        <GatsbyImage
          image={getImage(data.image3)}
          alt="rabbits"
          className="order-4 w-full md:w-2/7 mb-4 rounded-xl"
          style={{ maxWidth: 800 }}
        />
        <p className="order-2 md:order-last">
          We board various small animals including rabbits, hamsters and guinea
          pigs. We do ask that you bring your pet in their own habitat to ensure
          they are safe and comfortable. We also ask that you bring their own
          food as well as any treats or toys they may enjoy during their stay.
          On several occasions we have even boarded animals such as calves and
          goats.
          <Link to="/contact/"> Contact us</Link> to see if we can board your
          pet!
        </p>
      </div>
      <Link className="btn btn-light my-4" to="/what-to-bring/">
        What to Bring
      </Link>
    </Layout>
  );
};

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "goat.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image2: file(relativePath: { eq: "guinea_pigs.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image3: file(relativePath: { eq: "rabits.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;

export default SmallBoarding;
